import * as React from 'react';

export default props => {
  return (
    <div className={`container`}>
      <div className={`row mt-5 mb-0 mb-md-5 py-0 py-md-5 d-flex align-items-center`}>
        <div className={`col-12 col-md-5 p-0 pr-md-3 pr-lg-5 order-2 order-md-1 mt-5 mt-md-0`}>
          <img className={`w-100`} src="/images/index/anna-weber-nilges.jpg" alt="Anna Weber-Nilges"/>
        </div>
        <div className={`col-12 col-md-7 pl-md-3 pl-lg-5 order-1 order-md-2`}>
          <h2>Herzlich Willkommen</h2>
          <p className={`mt-4`}>
            Ich freue mich, Sie auf meiner Seite begrüßen zu dürfen. Ich bin seit 2014 als Kinder- und Jugendlichenpsychotherapeutin in eigener Praxis in Hachenburg im Westerwaldkreis tätig.
          </p>
          <p className={`pt-2 mb-0`}>
            Auf den folgenden Seiten möchte ich einen Einblick in meine Praxis geben. Ich hoffe, ich kann Ihnen damit mich und meine Arbeit ein Stück näher bringen und erste Fragen beantworten.
          </p>
        </div>
      </div>
    </div>
  )
}
