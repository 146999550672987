import * as React from 'react';
import Styles from './quote.module.scss';

export default props => {
  return (
    <div className={`container-fluid bg-green`}>
      <div className={`container py-5`}>
        <div className={`row py-0 py-md-5 d-flex justify-content-center`}>
          <div className={`col-12 col-xl-9 text-center`}>
            <p className={`color-white mb-0 ${Styles.quoteText}`}>
              Die Erfahrungen, die wir in unserem Leben gemacht haben, hemmende wie fördernde, tragen wir als Erlebens- und Verhaltensmuster mit uns. In der tiefenpsychologischen Behandlung geht es mir darum, diese Muster <b>gemeinsam</b> zu entdecken und schließlich zu verändern.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
