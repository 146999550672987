import React, { useState } from 'react';
import Styles from './gallery.module.scss';

const IMAGES = [
  '/images/index/gallery/weber-nilges-galerie-1.jpg',
  '/images/index/gallery/weber-nilges-galerie-2.jpg',
  '/images/index/gallery/weber-nilges-galerie-3.jpg',
  '/images/index/gallery/weber-nilges-galerie-4.jpg',
  '/images/index/gallery/weber-nilges-galerie-5.jpg',
  '/images/index/gallery/weber-nilges-galerie-6.jpg',
];

export default props => {

  const [visibleImage, setVisibleImage] = useState(IMAGES[0]);

  const nextImage = () => {
    setVisibleImage(IMAGES.indexOf(visibleImage) < (IMAGES.length - 1) ? IMAGES[IMAGES.indexOf(visibleImage) + 1] : IMAGES[0]);
  };

  const previousImage = () => {
    setVisibleImage(IMAGES.indexOf(visibleImage) > 0 ? IMAGES[IMAGES.indexOf(visibleImage) - 1] : IMAGES[IMAGES.length - 1]);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="container">
        <div className="row">
          {
            IMAGES.map((image, index)=> (
              <div key={index} className={`text-center col-12 ${Styles.fadeIn} ${(visibleImage === image ? 'd-block' : 'd-none')}`}>
                <img className={`${Styles.galleryImage}`} src={image} alt=""/>
              </div>
            ))
          }
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-center">
            <div className="font-weight-bold">
              {IMAGES.indexOf(visibleImage) + 1} / {IMAGES.length}
            </div>
            <div>
              <img onClick={() => previousImage()} draggable="false" className={`mr-4 ${Styles.clickable}`} alt="Pfeil Links" src="/images/global/arrow-left.svg"/>
              <img onClick={() => nextImage()} draggable="false"  className={`${Styles.clickable}`} alt="Pfeil Rechts" src="/images/global/arrow-right.svg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
