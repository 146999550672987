import * as React from 'react';
import Styles from './header.module.scss';

export default props => {
  return (
    <div className={`d-flex align-items-end ${Styles.headerImage}`}>
      <div className="container">
        <div className="row mb-5 pb-5 pb-0 pb-lg-5 position-relative">
          <div className="col-12 col-md-9 col-lg-10 color-white">
            <h1 className="text-uppercase">
              Praxis Anna Weber-Nilges
            </h1>
            <p className={`mt-3 ${Styles.subLine}`}>
              Psychotherapie für Kinder, Jugendliche und junge Erwachsene
            </p>
          </div>
          <div className={`d-none d-md-block position-absolute ${Styles.arrowDown}`}>
            <img src="/images/global/arrow-down.svg" alt="Navigationspfeil unten"/>
          </div>
        </div>
      </div>
    </div>
  )
}
