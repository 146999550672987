import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/global/layout/layout';
import Header from '../components/index/header/header';
import About from '../components/index/about/about';
import Quote from '../components/index/quote/quote';
import Gallery from '../components/global/gallery/gallery';

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}>
      <Header/>
      <About/>
      <Quote/>
      <Gallery/>
    </Layout>
  );
}
